<template>
    <div ref="tooltip-container" :class="containerClasses">
        <div data-bs-toggle="tooltip"
            :data-bs-original-title="titleText"
            tabindex="0"
           
            @blur="tooltips[0].hide()"
            >
            <div :style="displayTextStyles" :class="displayTextClasses">{{ displayText }}</div>
        </div>
        <!-- <a data-bs-toggle="tooltip"
            :title="titleText"
            :class="buttonClasses" 
            :href="href"
            :target="target"
            tabindex="0"
            @blur="tooltips[0].hide()"
            >{{ displayText }}
        </a> -->
    </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import initToolTip from '@/services/lib/tooltipInit';

export default {
    props: {
        displayText: {
            type: String,
            required: true
        },
        containerClasses: {
            type: Array,
            default: () => []
        },
        titleText: {
            type: String,
            default: () => 'Coming Soon!'
        },
        displayTextClasses: {
            type:String,
            default: () => ''
        },
        displayTextStyles: {
            type: String,
            default: () => ''
        }
       
    },
    setup() {
        const tooltipContainer = ref(null)
        const tooltips = ref([]);
        onMounted(() => {
            tooltips.value = initToolTip(tooltipContainer.value);
        });

        return {
            'tooltip-container': tooltipContainer,
            tooltips
        }
    },
}
</script>

<style>
.fa-tooltip-icon {
  outline: none;
}
</style>