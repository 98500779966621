<template>
  <cropper
    ref="cropper"
    class="cropper"
    :src="image.src"
    :stencil-props="{
      aspectRatio: 10 / 10,
    }"
  >
  </cropper>
  <button
    v-if="!new_image"
    class="btn btn-sm btn-outline-primary me-2"
    :disabled="new_image"
    @click="$refs.file.click()"
  >
    <input
      id="file"
      ref="file"
      type="file"
      style="display: none"
      accept="image/*"
      @change="loadImage($event)"
    />
    Upload Photo
  </button>
  <button
    v-if="new_image"
    class="btn btn-sm btn-outline-primary me-2"
    @click="saveImage()"
  >
    Save Image
  </button>
  <button
    v-if="new_image"
    class="btn btn-sm btn-outline-secondary me-2"
    @click="imageCancel()"
  >
    Cancel
  </button>
</template>
<script>
import { inject, ref, nextTick, getCurrentInstance, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { Cropper } from 'vue-advanced-cropper';

export default {
  components: {
    Cropper,
  },

  setup() {
    const api = inject('api');
    const loading = inject('$loading');
    const userApi = api.userApi();
    const router = useRouter();

    const vm = ref(getCurrentInstance());
    const store = useStore();
    const cropper = ref(null);
    const photoUploadContainer = ref(null);
    const image = ref({ src: null, type: null });

    const new_image = ref(false);

    const profilePicSrc = (pid) => {
      return `${process.env.VUE_APP_SERVER_URL}/api/user/pic/${pid}`;
    };
    const patientId = ref('');

    const getMimeType = (file, fallback = null) => {
      const byteArray = new Uint8Array(file).subarray(0, 4);
      let header = '';
      for (let i = 0; i < byteArray.length; i++) {
        header += byteArray[i].toString(16);
      }

      switch (header) {
        case '89504e47':
          return 'image/png';
        case 'ffd8ffe8':
          return 'image/jpeg';
        default:
          return fallback;
      }
    };

    const loadImage = (event) => {
      new_image.value = false;
      const { files } = event.target;
      if (files && files[0]) {
        if (image.value.src) {
          URL.revokeObjectURL(image.value.src);
        }

        const reader = new FileReader();

        reader.onload = (e) => {
          image.value.src = e.target.result;
          image.value.type = getMimeType(e.target.result, files[0].type);
          new_image.value = true;
        };

        reader.readAsDataURL(files[0]);
      }
    };

    const saveImage = async () => {
      const { canvas } = cropper.value.getResult();
      const imageResult = await processImage(canvas);
      const loader = loading.show({
        container: photoUploadContainer.value,
        isFullPage: false,
      });
      try {
        const payload = new FormData();
        payload.append('file', imageResult);
        await userApi.setProfilePic({ data: imageResult });
      } catch (error) {
        console.log(error);
      }
      loader.hide();
      new_image.value = null;
      router.go();
    };

    const processImage = async (canvas) => {
      const result = canvas.toDataURL(image.value.type);
      return result;
    };

    const imageCancel = () => {
      new_image.value = false;
      image.value = { src: null, type: null };
      cropper.value.reset();
    };

    onMounted(async () => {
      await nextTick();
      cropper.value = vm.value.refs.cropper;
      patientId.value = store.getters.userId;
      //   refresh();
    });

    return {
      image,
      patientId,
      'photo-upload-container': photoUploadContainer,
      cropper,
      profilePicSrc,
      //   cropChanged,
      new_image,
      loadImage,
      saveImage,
      imageCancel,
    };
  },
};
</script>
