<template>
  <div class="container-lg">
    <div class="row mb-4">
      <div class="col">
        <h3 class="text-center">
          We have detected that this is your first login. Please update your
          password.
        </h3>
      </div>
    </div>
    <div ref="change-pwd-container" class="row">
      <div class="col-8 offset-2">
        <form @submit.prevent="changePassword">
          <div class="row">
            <div class="col-6 offset-3">
              <password-ctrl
                v-model="password1Input"
                name="new-pwd"
                label="New Password"
                @changes-made="changesMade"
              ></password-ctrl>
              <password-strength-meter
                v-model="password1Input"
                :strength-meter-only="true"
                @score="updateScore"
              />
              <div class="mt-2 mb-3">
                <small class="text-muted pt-4"
                  >Strength: {{ passwordStrength }}</small
                >
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6 offset-3">
              <password-ctrl
                v-model="password2Input"
                name="confirm-pwd"
                label="Re-Enter New Password"
                @changes-made="changesMade"
              ></password-ctrl>
            </div>
          </div>
          <div class="row">
            <div class="col-6 offset-3 border-top pt-3">
              <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                <button
                  id="change-pwd-btn"
                  class="btn btn-primary me-md-2"
                  type="submit"
                  :disabled="!isSubmittable"
                >
                  Change Password
                </button>
              </div>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-6 offset-3">
              <div
                class="alert bg-light border border-1 border-secondary"
                role="alert"
              >
                <div
                  class="small"
                  :class="{
                    'text-success': !passwordIssues.lengthIssue,
                    'text-danger': passwordIssues.lengthIssue,
                  }"
                >
                  <i
                    v-if="!passwordIssues.lengthIssue"
                    class="fas fa-check-circle"
                  ></i
                  ><i v-else class="fas fa-times"></i>
                  at least 8 characters
                </div>
                <div
                  class="small"
                  :class="{
                    'text-success': !passwordIssues.number,
                    'text-danger': passwordIssues.number,
                  }"
                >
                  <i
                    v-if="!passwordIssues.number"
                    class="fas fa-check-circle"
                  ></i
                  ><i v-else class="fas fa-times"></i>
                  at least 1 number
                </div>
                <div
                  class="small"
                  :class="{
                    'text-success': !passwordIssues.lowercase,
                    'text-danger': passwordIssues.lowercase,
                  }"
                >
                  <i
                    v-if="!passwordIssues.lowercase"
                    class="fas fa-check-circle"
                  ></i
                  ><i v-else class="fas fa-times"></i>
                  at least 1 lowercase letter
                </div>
                <div
                  class="small"
                  :class="{
                    'text-success': !passwordIssues.uppercase,
                    'text-danger': passwordIssues.uppercase,
                  }"
                >
                  <i
                    v-if="!passwordIssues.uppercase"
                    class="fas fa-check-circle"
                  ></i
                  ><i v-else class="fas fa-times"></i>
                  at least 1 uppercase letter
                </div>
                <div
                  class="small"
                  :class="{
                    'text-success': !passwordIssues.special,
                    'text-danger': passwordIssues.special,
                  }"
                >
                  <i
                    v-if="!passwordIssues.special"
                    class="fas fa-check-circle"
                  ></i
                  ><i v-else class="fas fa-times"></i> at least 1 of the
                  following characters:<br />
                  ,.&lt;>/?`~!@#$%^&amp;*()-_=+[]{}|;:
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { inject, ref, onMounted, computed } from 'vue';
import PasswordCtrl from '../../auth2/components/PasswordCtrl.vue';
import PasswordStrengthMeter from '../../auth2/components/PasswordStrengthMeter.vue';
import { useRouter } from 'vue-router';

export default {
  components: {
    PasswordCtrl,
    PasswordStrengthMeter,
  },
  setup() {
    const api = inject('api').authApi();
    const loading = inject('$loading');

    const password1Input = ref('');
    const password2Input = ref('');
    const router = useRouter();
    const changePwdContainer = ref(null);

    onMounted(async () => {
      const loader = loading.show({
        container: changePwdContainer.value,
        isFullPage: false,
      });
      doRealTimeValidation();
      loader.hide();
    });

    // update password strength indicator's score
    const pwdStrengths = [
      'very weak',
      'weak',
      'moderate',
      'strong',
      'very strong',
    ];
    const passwordScore = ref(0);
    const updateScore = (score) => {
      passwordScore.value = score || 0;
    };

    // returns word describing strength of current pwd input
    const passwordStrength = computed(() => {
      return pwdStrengths[passwordScore.value];
    });

    // form is submittable if password inputs aren't empty and validate
    const isSubmittable = computed(() => {
      return (
        password1Input.value && password2Input.value && formValidated.value
      );
    });

    const formValidated = ref(false);
    const doRealTimeValidation = () => {
      formValidated.value = false;
      const numberRegex = /\d/;
      const lowercaseRegex = /^(.*[a-z].*)$/;
      const uppercaseRegex = /^(.*[A-Z].*)$/;
      const specialCharRegex = /[,.<>/?`~!@#$%^&*()\-_=+[\]{}|;:]/;

      passwordIssues.value.lengthIssue =
        password1Input.value.length < 8
          ? 'Password must be at least 8 characters'
          : '';
      passwordIssues.value.number = !numberRegex.test(password1Input.value)
        ? 'Password must contain at least 1 number'
        : '';
      passwordIssues.value.lowercase = !password1Input.value.match(
        lowercaseRegex
      )
        ? 'Password must contain at least 1 lowercase letter'
        : '';
      passwordIssues.value.uppercase = !password1Input.value.match(
        uppercaseRegex
      )
        ? 'Password must contain at least 1 uppercase letter'
        : '';
      passwordIssues.value.special = !specialCharRegex.test(
        password1Input.value
      )
        ? 'Password must contain at least 1 of the following special characters:  ,.<>/?`~!@#$%^&*()-_=+[]{}|;:'
        : '';

      formValidated.value =
        !(
          passwordIssues.value.lengthIssue ||
          passwordIssues.value.number ||
          passwordIssues.value.lowercase ||
          passwordIssues.value.uppercase ||
          passwordIssues.value.passwordIssues
        ) && password1Input.value == password2Input.value;
    };

    const passwordIssues = ref({});

    // change password
    const submissionResult = ref('');
    const changePassword = async () => {
      if (!isSubmittable.value) return;
      submissionResult.value = '';
      const loader = loading.show({
        container: changePwdContainer.value,
        isFullPage: false,
      });
      await api.reset_password(password1Input.value);
      router.push({ path: '/', replace: true });
      loader.hide();
    };

    const changesMade = () => {
      doRealTimeValidation();
    };

    return {
      password1Input,
      password2Input,
      'change-pwd-container': changePwdContainer,
      passwordStrength,
      updateScore,
      isSubmittable,
      changePassword,
      doRealTimeValidation,
      passwordIssues,
      changesMade,
    };
  },
};
</script>
