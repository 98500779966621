<template>
  <div ref="calendar-container" class="container-lg position-relative">
    <div class="d-flex">
      <h1 class="me-sm-3">
        {{
          selectedDate
          ? selectedDate.toLocaleDateString(undefined, {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          })
          : 'My Calendar'
        }}
      </h1>
      <div class="ms-auto me-2">
        <div class="form-check">
          <input id="month-view" v-model="displayPeriod" class="form-check-input" type="radio" value="month" />
          <label class="form-check-label" for="month-view"> Month View </label>
        </div>
        <div class="form-check">
          <input id="week-view" v-model="displayPeriod" class="form-check-input" type="radio" value="week" />
          <label class="form-check-label" for="week-view"> Week View </label>
        </div>
      </div>
    </div>

    <!-- Weekly/Monthly Calendar -->

    <div class="row">
      <div class="col">
        <div id="calendar-container" :style="{ 'min-height': displayPeriod === 'month' ? '67vh' : '30vh' }">
          <calendar-view class="theme-default" :items="calendarTasks" :show-date="showDate"
            :display-period-uom="displayPeriod" :period-changed-callback="refresh" :enable-date-selection="false"
            @click-date="clickDate" @click-item="clickTask">
            <template #header="{ headerProps }">
              <calendar-header :header-props="headerProps" @input="showDate = $event" />
            </template>
            <template #dayContent="{ day }">
              <div v-if="!dayIsEmpty(day)" class="d-sm-none">
                <i class="fas fa-xs fa-circle text-warning"></i>
              </div>
            </template>
            <!-- eslint-disable vue/no-v-html -->
            <template #item="{ value, top }">
              <div :style="`top: ${top}`" :value="value" class="cv-item d-sm-block d-none" :class="value.classes"
                :title="htmlToPlainText(value.title)" @click="clickTask(value)">
                {{ htmlToPlainText(value.title) }}
              </div>
            </template>
            <!-- eslint-enable -->
          </calendar-view>
        </div>
      </div>
    </div>

    <!-- Tasks Detailed View -->
    <div v-if="selectedDate" class="table-responsive border-bottom mt-3 mb-3">
      <table v-if="selectedTasks.length > 0"
        class="table table-small align-middle table-borderless table-striped mb-0 lh-sm border border-1 border-primary">
        <thead class="bg-sky text-white">
          <tr>
            <th></th>
            <th class="w-100" scope="col">
              {{ selectedDate.toDateString() }}
            </th>
            <th>
              <div>
                <button type="button" class="btn p-0 ms-4" @click="openCreateModal">
                  <i class="fas fa-plus text-secondary"></i>
                </button>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="task in selectedTasks" :key="`${task.type}-${task.id}`">
            <td>
              <i v-if="task.isCompleted" class="fas fa-check text-primary p-2" @click="undoCompleteTask(task)"></i>
              <button v-else type="button" class="btn btn-sm" @click="completeTask(task)">
                <i class="far fa-square"></i>
              </button>
            </td>
            <td>
              <i v-if="task.type == 'Diversion'" class="fas fa-sm fa-rocket text-primary me-1"></i>
              <!-- eslint-disable vue/no-v-html -->
              <span class="ql-editor" v-html="task.title"></span>
              <span v-if="task.hasTimeValue">
                - {{ convertToLocalTime(task.dueDateUtc) }}
              </span>
              <!-- eslint-enable -->
              <p v-if="task.goalTitle" class="p-0 m-0">
                {{ task.goalTitle }}
              </p>
              <p v-else-if="task.description" class="small p-0 m-0">
                {{ task.description }}
              </p>
            </td>
            <td class="text-end small">
              <div v-if="task.type != 'GoalAction'">
                <div v-if="toBeDeleted != task.id" class="btn-group">
                  <edit-todo-modal v-if="task.type == 'Todo'" :todo="task" @updated="refresh()" @clean="isDirty = false"
                    @dirty="isDirty = true" />
                  <button type="button" class="btn btn-sm" @click="toBeDeleted = task.id">
                    <i class="fas fa-trash-alt"></i>
                  </button>
                </div>
                <div v-else class="btn-group">
                  <button v-if="!task.isRecurring" :id="`confirm-delete-btn-${task.id}`" type="button"
                    class="btn btn-sm btn-danger" @click="deleteTask(task)">
                    Delete
                  </button>
                  <button v-else :id="`confirm-delete-btn-${task.id}`" type="button" class="btn btn-sm btn-danger"
                    @click="deleteTask(task)">
                    Delete Series
                  </button>
                  <button :id="`cancel-delete-btn-${task.id}`" type="button" class="btn btn-sm btn-secondary ms-2"
                    @click="toBeDeleted = null">
                    Cancel
                  </button>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div v-else class="text-center">
        <span class="text-muted">
          No tasks for {{ selectedDate.toDateString() }}</span>
        <create-todo-modal :date="selectedDate" :btn-classes="{ btn: true, 'pb-2': true }" @add-todo="refresh()"
          @dirty="isDirty = true" @clean="isDirty = false" />
      </div>
    </div>
    <create-todo-modal ref="create-todo-calendar-modal" :date="selectedDate" :btn-classes="{ 'd-none': true }"
      @add-todo="refresh()" @dirty="isDirty = true" @clean="isDirty = false" />
  </div>
</template>

<script>
import { ref, inject, computed } from 'vue';
import CreateTodoModal from '../todos/CreateTodoModal.vue';
import EditTodoModal from '../todos/EditTodoModal.vue';
import CalendarHeader from './CalendarHeader.vue';
import { CalendarView } from 'vue-simple-calendar';
import '../../../../node_modules/vue-simple-calendar/dist/style.css';
import '../../../../node_modules/vue-simple-calendar/dist/css/default.css';
import stdLocalDateTime, {
  getFormattedLocalTime,
  getLocalDateTime,
} from '../../../services/lib/localTime';
import { areDatesSameDay } from '../../../services/lib/dateComparisons';

export default {
  components: {
    CreateTodoModal,
    EditTodoModal,
    CalendarView,
    CalendarHeader,
  },
  setup() {
    const patientApi = inject('api').patientApi();
    const userApi = inject('api').userApi();
    const showDate = ref(new Date());
    const displayPeriod = ref('month');

    const tasks = ref([]);
    const calendarTasks = ref([])
    const loading = inject('$loading');
    const calendarContainer = ref(null);

    const refresh = async (dateRange) => {
      const loader = loading.show({
        container: calendarContainer.value,
        isFullPage: false,
      });
      let start = null;
      let end = null;
      if (!dateRange) {
        start = new Date(selectedDate.value);
        end = new Date(selectedDate.value);
        start.setDate(start.getDate() - 41);
        end.setDate(end.getDate() + 41);
      } else {
        start = new Date(dateRange.value.displayFirstDate.value.toISOString());
        end = new Date(dateRange.value.displayLastDate.value.toISOString());
        start.setDate(start.getDate() - 7);
        end.setDate(end.getDate() + 7);
        selectedDate.value = null;
      }
      console.log('start:', start);
      console.log('end:', end);

      const response = await userApi.getTasks(
        stdLocalDateTime(start, true, 'YYYY MM DD'),
        stdLocalDateTime(end, true, 'YYYY MM DD')
      );
      //dates are stored in UTC, calendar is now time aware so we have to convert these
      //to local datetimes
      tasks.value = response.data.map((task) => ({
        ...task,
        startDate: getLocalDateTime(task.dueDateUtc),
      }));

      // const groupedTasks = [...tasks.value].reduce((acc, task) => {
      //   const dueDate = task.dueDateUtc.split('T')[0]; // Extract date part
      //   if (!acc[dueDate]) {
      //     acc[dueDate] = [];
      //   }

      //   // Check if the date has less than 3 tasks
      //   if (acc[dueDate].length < 3) {
      //     acc[dueDate].push(task);
      //   }

      //   return acc;
      // }, {});

      calendarTasks.value = tasks.value

      // Object.entries(groupedTasks).forEach(([date, tasks]) => {
      //   console.log(date)
      //   calendarTasks.value.push(...tasks.map((item) => {
      //     // if (index === 2) {
      //     //   return {
      //     //     ...item,
      //     //     title: `and more`,
      //     //   }
      //     // }
      //     return item
      //   }))
      // });


      loader.hide();
    };

    const selectedDate = ref(null);

    const clickDate = (date) => {
      selectedDate.value = date;
    };

    const clickTask = (event) => {
      console.log('event.originalItem.startDate', event.originalItem.startDate);
      //selectedDate.value = new Date(event.originalItem.startDate.replace(/-/g, '/').replace(/T.+/, ''));
      selectedDate.value = new Date(event.originalItem.startDate);
    };

    const selectedTasks = computed(() => {
      return tasks.value.filter((task) =>
        areDatesSameDay(task.startDate, selectedDate.value)
      );
    });

    const isDirty = ref(false);
    const toBeDeleted = ref(null);

    const deleteTask = async (item) => {
      console.log(item);
      if (item.type == 'Todo') await userApi.deleteTodo(item.taskId);
      if (item.type == 'Diversion')
        await patientApi.deleteActiveDiversion(item.taskId);
      refresh();
    };

    const completeTask = async (item) => {
      if (item.type == 'GoalAction') {
        await patientApi.completeGoalAction(item.taskId, true);
      } else if (item.type == 'Todo') {
        await userApi.updateTodoCompletion(item.taskId, true);
      } else if (item.type == 'Diversion') {
        await patientApi.completeDiversion(item.taskId, true);
      } else return;
      refresh();
    };

    const undoCompleteTask = async (item) => {
      if (item.type == 'GoalAction') {
        await patientApi.completeGoalAction(item.taskId, false);
      } else if (item.type == 'Todo') {
        await userApi.updateTodoCompletion(item.taskId, false);
      } else if (item.type == 'Diversion') {
        await patientApi.completeDiversion(item.taskId, false);
      } else return;
      refresh();
    };

    const htmlToPlainText = (richText) => {
      console.log(richText, "asdsad")
      return richText.replace(/<[^>]*>/g, '');
    };

    const dayIsEmpty = (day) => {
      const dayDate = new Date(day);
      const dayTasks = tasks.value.filter((task) =>
        areDatesSameDay(task.startDate, dayDate)
      );

      if (dayTasks.length === 0) {
        console.debug(`day ${day} is empty`);
      }
      return dayTasks.length === 0;
    };

    const createTodoCalendarModal = ref(null);
    const openCreateModal = () => {
      createTodoCalendarModal.value.openModal();
    };

    const convertToLocalTime = (utcTime) => {
      if (!utcTime) {
        return '';
      }
      return getFormattedLocalTime(utcTime);
    };

    return {
      showDate,
      displayPeriod,
      refresh,
      tasks,
      calendarTasks,
      selectedDate,
      selectedTasks,
      clickDate,
      clickTask,
      isDirty,
      toBeDeleted,
      deleteTask,
      completeTask,
      undoCompleteTask,
      htmlToPlainText,
      dayIsEmpty,
      'create-todo-calendar-modal': createTodoCalendarModal,
      openCreateModal,
      'calendar-container': calendarContainer,
      convertToLocalTime,
    };
  },
};
</script>

<style>
#calendar-container {
  color: #2c3e50;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: #e5e5e5;
}

.cv-item {
  background-color: rgb(204, 204, 212);
  /* width: calc((100% / 7) - 0.65em) !important;
  margin:0 5px */
}

.today {
  background-color: rgb(151, 157, 190) !important;
  color: white;
}
</style>
